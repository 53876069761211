import laurentPicture from '../images/laurent.jpeg';
import maelysPicture from '../images/maelys.jpeg';
import anaelPicture from '../images/anael.jpeg';
import julienPicture from '../images/julien.jpeg';
import kevinPicture from '../images/kevin.jpeg';

const testimonials = [
  {
    content: 'Adminmate saved us a huge amount of time we have been able to invest in our product instead.',
    title: 'Laurent, CEO of Awesome Lab',
    thumbnail: laurentPicture
  },
  {
    content: 'I would be lost without Adminmate. Thanks to Adminmate, I can manage over 10 different businesses databases for my agency from the same place.',
    title: 'Maëlys, CEO of EOS Agency',
    thumbnail: maelysPicture
  },
  {
    content: 'Adminmate made my backoffice development painless, pleasant and most of all hassle free! It is a life changing solution for me.',
    title: 'Anael Otge, Founder of Otge Consulting',
    thumbnail: anaelPicture
  },
  {
    content: 'Adminmate impressed me on multiple levels. This is really a no-brainer solution for such a low price!',
    title: 'Julien, CMO of IDEA Conseils',
    thumbnail: julienPicture
  },
  {
    content: 'Adminmate is the most valuable tech resource we have EVER purchased. It made my data operations easier for me and my team.',
    title: 'Kévin Mazars, CEO of Vrai Studio',
    thumbnail: kevinPicture
  }
];

export default testimonials;