import React from 'react';

// Layout
import Layout from '../components/layout';
import CTABlock from '../components/cta_block';

// Databases
import MysqlIcon from '../images/databases/mysql.png';
import MongodbIcon from '../images/databases/mongodb.png';
import PostgresqlIcon from '../images/databases/postgresql-flat.png';
import SqliteIcon from '../images/databases/sqlite-flat.png';

// Data
import config from '../data/general';
import FAQBlock from '../components/FAQ';

const AboutPage = () => {
  return (
    <Layout pageTitle="Adminmate — About">
      <>
        <CTABlock />

        <div className="container mx-auto mt-8">

          <FAQBlock />

          <div className="mx-auto border-t"></div>

          <div className="py-8 md:py-16 w-5/6 md:w-4/5 mx-auto">
            <h2 className="text-3xl font-bold leading-tight text-center">Compatible with all major databases</h2>
            <div className="flex justify-center mt-8 md:mt-14">
              <div className="text-center flex-shrink-0">
                <img src={MysqlIcon} alt="Mysql" className="h-12 md:h-20 mx-auto" />
                <div className="mt-1 md:mt-3 text-sm text-gray-500">mysql</div>
              </div>
              <div className="w-6 md:w-12"></div>
              <div className="text-center flex-shrink-0">
                <img src={PostgresqlIcon} alt="Postgresql" className="h-12 md:h-20 mx-auto" />
                <div className="mt-1 md:mt-3 text-sm text-gray-500">postgresql</div>
              </div>
              <div className="w-6 md:w-12"></div>
              <div className="text-center flex-shrink-0">
                <img src={SqliteIcon} alt="sqlite" className="h-12 md:h-20 mx-auto" />
                <div className="mt-1 md:mt-3 text-sm text-gray-500">sqlite</div>
              </div>
              <div className="w-6 md:w-12"></div>
              <div className="text-center flex-shrink-0">
                <img src={MongodbIcon} alt="sqlite" className="h-12 md:h-20 mx-auto" />
                <div className="mt-1 md:mt-3 text-sm text-gray-500">mongodb</div>
              </div>
            </div>
          </div>
        </div>

        <div className="container mx-auto">
          <div className="w-5/6 md:w-4/5 mx-auto">
            <div className="bg-gray-100 rounded-xl mt-6 mb-12 md:mb-20">
              <div className="container px-6 py-8 mx-auto sm:px-6 lg:py-16 lg:px-12 lg:flex lg:items-center lg:justify-between">
                <h3 className="text-2xl font-semibold tracking-tight text-gray-900 leading-7 sm:text-4xl sm:leading-10">
                  <div>Ready to empower your data?</div>
                  <div className="text-amblue hover:opacity-90 mt-1">Connect your database for free.</div>
                </h3>
                <div className="flex mt-8 lg:flex-shrink-0 lg:mt-0">
                  <div className="inline-flex rounded-md">
                    <a href={config.signup_link} className="px-5 py-3 border border-transparent rounded-md text-base font-medium text-white transition duration-500 ease-in-out bg-amcolor hover:bg-amcolor-darker">Get started for free</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </Layout>
  )
};

export default AboutPage;