import * as React from 'react';
import marcPicture from '../images/marc-colored.jpeg';
import charlesPicture from '../images/charles-colored.png';
import testimonials from '../data/testimonials';

const CTABlock = () => {
  return (
    <div className="bg-gray-50 py-16 px-8 md:px-16 lg:px-0">
      <div className="container mx-auto">

        {/* Image */}
        <div className="pb-8">
          <div className="lg:w-3/12 col-md-4 mx-auto text-center flex">
            <img src={marcPicture} className="mx-auto w-24 lg:w-40 rounded-full" alt="Marc Delalonde" data-ll-status="observed" />
            <img src={charlesPicture} className="mx-auto w-24 lg:w-40 rounded-full" alt="Marc Delalonde" data-ll-status="observed" />
          </div>
        </div>

        {/* Content */}
        <div className="lg:w-3/6 mx-auto text-center">
          <h2 className="text-2xl md:text-4xl lg:text-4xl font-bold">Why did we create Adminmate?</h2>
          {/* <p className="text-md md:text-xl lg:text-lg pt-4 pb-4 text-gray-800">As a senior web engineer that have worked for many startups and agencies, my main pain point was: how can I access the databases data on a efficient way and mostly how I give access to the data to the non-tech profile for them to manage / monitor / track it ?</p> */}
          <p className="text-md md:text-xl lg:text-lg pt-4 pb-4 text-gray-800">As a team of seasoned web engineers and marketers, we have a rich history of working in various startups and agencies, continually facing a common issue: enabling non-tech individuals to access and manage data efficiently. Each new project resulted in a time-consuming redevelopment of a back-office solution, highlighting the need for a more flexible, one-size-fits-all approach.</p>

          <p className="text-md md:text-xl lg:text-lg pb-4 text-gray-800">Our expertise isn't confined to web engineering. We co-founded a Shopify App Studio, Penida, where we use Adminmate daily. This venture offered unique insights into the challenges of diverse project needs, particularly in managing and integrating data. Adminmate is a robust tool that is used - amongst other things - to run a Shopify App Studio powering more than 22,500 shops across 85+ countries.</p>

          <p className="text-md md:text-xl lg:text-lg pb-4 text-gray-800">With Adminmate, we've strived to create a universal back-office solution that meets the demands of all project sizes, increases productivity, and saves time. This commitment epitomizes our mission to bridge the gap between tech and non-tech individuals.</p>

          <p className="text-md md:text-xl lg:text-lg pb-4 text-gray-800">Adminmate simplifies data management for non-tech individuals, and our back-office solution saves time on project-specific redevelopment. It is a flexible, one-size-fits-all solution that is used daily in our Shopify App Studio, Penida, where it effectively manages diverse project needs.</p>

          <p className="text-md md:text-xl lg:text-lg pb-4 text-gray-800">Our journey, as Marc and Charles, was focused on creating a bridge between technical and non-tech individuals. Adminmate is proof of our dedication and a testament to our ability to cater to even the most significant business needs, making it a trustworthy option for organizations of all sizes.</p>

          <p className="text-md md:text-xl lg:text-lg pb-4 text-gray-800">TL;DR: Adminmate was built to handle any scale of business, from small to large enterprises. We hope to onboard you as soon as today. Also, this was not written through chatGPT so thanks for reading <span role="img" aria-label="icon">❤️</span></p>

          {/* <p className="text-md md:text-xl lg:text-lg pb-8 text-gray-800">
            <div><span role="img" aria-label="icon">📊</span> Adminmate simplifies data management for non-tech individuals</div>
            <div><span role="img" aria-label="icon">⏱️</span> Our back-office solution saves time on project-specific redevelopment</div>
            <div><span role="img" aria-label="icon">📐</span> Adminmate is a flexible, one-size-fits-all solution</div>
            <div><span role="img" aria-label="icon">🔄</span> Used daily in our Shopify App Studio, Penida, it effectively manages diverse project needs</div>
            <div><span role="img" aria-label="icon">🧑‍💻🧙‍♂️</span> Our journey, as Marc and Charles, was focused on creating a bridge between technical and non-tech individuals.</div>
          </p> */}
        </div>

        {/* Testimonial */}
        <div className="mt-8">
          <div className="lg:w-1/2 mx-auto mt-4 text-center">
            <blockquote className="text-gray-700 italic text-sm  lg:text-lg">
              "{testimonials[1].content}"
            </blockquote>
            <div className="mt-3 flex justify-center content-center items-center">
                <img src={testimonials[1].thumbnail} className="w-8 self-start rounded-full" alt={testimonials[1].title} data-ll-status="observed" />
                <span className="ml-2 font-bold text-gray-900">{testimonials[1].title}</span>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};

export default CTABlock;
