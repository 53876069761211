import React from 'react';
import { Helmet } from 'react-helmet';

// Css
import '../css/styles.scss';

// Images
import favicon from '../images/favicon-32x32.png';
import ogImage from '../images/og-image.png';

// Components
import Menu from './menu';
import Footer from './footer';
import { FiArrowRight } from 'react-icons/fi';

export default function Layout({ children, pageTitle, pageDesc }) {
  const title = pageTitle || 'Adminmate — The all-in-one back-office solution for your team';
  // const description = `The simplest back-office solution for your team. Connect, Visualize, Track & Collaborate on your data in a better way.`;
  const description = pageDesc || 'The no-brainer back-office solution for your team. Connect your databases, visualize, track & collaborate on your data in a better way — 5 min setup & 100% customizable.';

  const metaArray = [
    { name: 'description', content: description },
    { name: 'viewport', content: 'width=device-width, initial-scale=1' },
    { name: 'og:title', content: title },
    { name: 'og:description', content: description },
    { name: 'og:image', content: ogImage },
    { name: 'og:image:width', content: 1200 },
    { name: 'og:image:height', content: 880 },
    { name: 'og:image:alt', content: 'Adminmate' },
    { name: 'twitter:title', content: title },
    { name: 'twitter:description', content: description },
    { name: 'twitter:image', content: ogImage },
    { name: 'twitter:image:alt', content: 'Adminmate' },
  ];

  const linkArray = [
    { rel: 'shortcut icon', href: favicon },
    {
      rel: 'stylesheet',
      href: 'https://maxst.icons8.com/vue-static/landings/line-awesome/line-awesome/1.3.0/css/line-awesome.min.css'
    }
  ];

  const scriptArray = [];
  // Plausible
  // if (true || process.env.NODE_ENV === 'production') {
  //   scriptArray.push({ defer: true, 'data-domain': 'adminmate.io', src: '/js/script.js' })
  // }

  return (
    <>
      <Helmet
        htmlAttributes={{ lang: 'en' }}
        title={title}
        meta={metaArray}
        link={linkArray}
        script={scriptArray}
      />

      <div className="bg-amblue text-center pt-2.5 pb-3.5 text-white">
        We should be on ProductHunt on <span className="font-semibold">August 28th</span>. <span className="underline">Be ready!</span>
        {/* <span className="underline">7 days left</span> for our offer at a 30% discount. <span className="inline-flex items-center font-semibold">Claim your discout&nbsp; <FiArrowRight /></span> */}
      </div>

      <Menu />

      {children}

      <Footer />
    </>
  );
};