import * as React from 'react';

const faq = [
  {
    title: 'What is Adminmate?',
    content: <>Adminmate is an innovative admin panel and dashboard designed to integrate seamlessly with your existing business applications. This all-in-one platform provides the tools you need to manage, analyze, and monitor your business operations effectively and securely.</>
  },
  {
    title: 'How does Adminmate work?',
    content: <>Adminmate operates as a Back-office that communicates with your databases. It provides a fully operational admin panel post-integration, which you can tailor to suit your specific business requirements.</>
  },
  {
    title: 'Can I try Adminmate for free?',
    content: <>Absolutely! We offer a free trial period so you can experience the power of Adminmate firsthand. Sign up on our website to begin your trial. We also have a free tier if you’re a solo developper that should be enough for your needs.</>
  },
  {
    title: 'How do I get started with Adminmate?',
    content: <>Kickstarting your Adminmate journey is as easy as pie. Simply sign up on our website, establish a connection with your existing databases, and you're all set to customize your admin panel.</>
  },
  {
    title: 'What are the primary features of Adminmate?',
    content: <>Adminmate boasts an array of features including data visualization, comprehensive data management, user management, custom workflows, role-based access control, notifications, and more - all designed to make your life easier.</>
  },
  {
    title: 'To what extent can I customize Adminmate?',
    content: <>Flexibility is at the core of Adminmate. You can tailor your admin panel layout, design elements, and features to match your unique business needs. If it's feasible, Adminmate can do it.</>
  },
  {
    title: 'How secure is Adminmate?',
    content: <>At Adminmate, your data security is our highest priority. We strictly follow industry-standard practices to ensure maximum security and data protection, including but not limited to, data encryption, secure user authentication, and role-based access control.</>
  },
  {
    title: 'Which applications and databases does Adminmate support?',
    content: <>Adminmate offers compatibility with a diverse range of databases. Some of the most popular ones include PostgreSQL, MongoDB and MySQL.</>
  },
  {
    title: 'What are the pricing plans for Adminmate?',
    content: <>Adminmate offers a variety of pricing plans, catered to meet the needs of businesses big and small. For more detailed information, please visit our pricing page here.</>
  },
  {
    title: 'Can upgrade/downgrade my plan?',
    content: <>You can stop or make changes to your subscription at any time. Please note that if you cancel or downgrade your subscription during a billing cycle (either a month or a year, depending on your selection), we cannot issue a refund for that billing cycle.</>
  },
  {
    title: 'Can I cancel my plan?',
    content: <>Yes. Please find our refund policy below.<br />
    <b>Full Refunds</b>: No full refunds are available after the start of a billing cycle.<br />
    <b>Partial Refunds</b>: No partial refunds will be given for cancellations or changes made within a billing cycle.<br />
    <b>Trial Period</b>: If a trial period is offered and you cancel within this period, no charges will be applied.</>
  },
  {
    title: 'Do you provide customer support?',
    content: <>Yes, indeed! Our customer support team is readily available to assist you via email and live chat. Any queries or issues you may have, we're here to help.</>
  },
  {
    title: 'Do you have access to my data?',
    content: <>The simple answer is no, never. Adminmate is built around data privacy and provides you an admin backend you have to deploy on your own servers. The admin backend is protected by your own passwords to secure your data, stores on YOUR server. It would be impossible even for a potential malicious Adminmate employee to access your data.</>
  },
  // {
  //   title: 'Which plan is best for me?',
  //   content: 'The Adminmate pricing is pretty simple. If you are a developer working on a side project with little needs, the free plan will be perfect for you. If you are working on a bigger project with a team, the premium plan will better suit your needs.'
  // },
  // {
  //   title: 'What payment methods do you accept?',
  //   content: 'We accept all major credit cards and ACH. If you want to be billed annually, please contact us.'
  // },
  // {
  //   title: 'Can I cancel my payment plan?',
  //   content: <>Sure, you can cancel your payment plan <b>whenever you want</b>. No questions asked. However, every feedbacks are welcome to help us improve our product.</>
  // },
  // {
  //   title: 'What happens if I cancel my payment plan?',
  //   content: 'When you cancel your plan, you will remain on the plan until the end of your billing cycle. Then, we will cancel your plan and downgrade your account to our free tier.'
  // }
];

const FAQBlock = () => {
  const [ activeBlocks, setActiveBlocks ] = React.useState([]);

  const toggleKey = key => {
    if (activeBlocks.includes(key)) {
      setActiveBlocks(activeBlocks.filter(k => k !== key));
    }
    else {
      const newArray = [...activeBlocks];
      newArray.push(key);
      setActiveBlocks(newArray);
    }
  };

  return (
    <div className="px-7 mx-auto max-w-7xl py-20" id="faq">
      <h2 className="text-3xl md:text-4xl lg:text-5xl font-bold leading-tight text-center mb-8 md:mb-14">Frequently Asked Questions</h2>

      {faq.map((item, key) => {
        let iconClass = 'las la-angle-down text-xl text-gray-500 ml-2 mt-1 md:mt-0 md:text-2xl';
        let titleClass = 'font-semibold text-lg md:text-xl';
        let pClass = 'text-gray-700 hidden leading-7 mt-2';

        if (activeBlocks.includes(key)) {
          iconClass = iconClass.replace('-down', '-up');
          titleClass += ' mb-3';
          pClass = pClass.replace('hidden', '');
        }

        return (
          <div
            key={key}
            className="bg-white border border-gray-300 rounded-xl p-6 mb-5 relative md:p-8 md:mb-7 cursor-pointer"
            onClick={() => toggleKey(key)}
          >
            <div className="flex">
              <div className="flex-1">
                <div className={titleClass}>{item.title}</div>
              </div>
              <i className={iconClass}></i>
            </div>
            <p className={pClass}>{item.content}</p>
          </div>
        );
      })}
    </div>
  );
};

export default FAQBlock;